<template>
  <div class="block">
    <div class="headline">
      <span>{{ $attrs.title }}</span>
      <!-- <span style="color: blue; cursor: pointer;" @click="visible = !visible">{{visible ? '收起' : '展开'}}</span> -->
      <template v-if="editable == true">
        <img
          src="@/assets/edit.svg"
          v-if="status == 'saved'"
          alt="编辑"
          @click="changeStatus('edit')"
          class="blue"
        />
        <span
          class="blue"
          @click="changeStatus('saved')"
          v-if="status == 'edit'"
          >保存</span
        >
      </template>
    </div>
    <div class="content" v-show="visible">
      <slot :status="status"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "block",
  data() {
    let status = this.$attrs['status'] || this.$props['status'];
    let visible = this.$attrs['visible'] || this.$props['visible'];
    return {
      status: status ? status : "edit",
      visible: visible ? visible : true
    };
  },
  props: {
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    changeStatus(status) {
      this.status = status;
      this.$emit("change", status);
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding-bottom: 8px;
    height: 32px;
    span {
      font-weight: 600;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .content {
    padding-top: 16px;
  }
}
</style>