<template>
  <div class="form-content">
    <div class="form-group">
      <div class="form-group_item">
        <label>姓名:</label>
        <span>{{data.staffName}}</span>
      </div>
      <div class="form-group_item">
        <label>聘用部门:</label>
        <span>{{data.sysDeptName?data.sysDeptName.deptName:''}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>聘用岗位:</label>
        <span>{{data.sysPositionNameList&&data.sysPositionNameList.length?data.sysPositionNameList.map((e) => e.positionName).join(","):''}}</span>
      </div>
      <div class="form-group_item">
        <label>变动原因:</label>
        <span>{{data.changeReason}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>签订类别:</label>
        <span>{{data.contractType==1?'首签':'续签'}}</span>
      </div>
      <div class="form-group_item">
        <label>首入中粮时间:</label>
        <span>{{data.firstContractTime?data.firstContractTime:'---'}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>原合同主体:</label>
        <span>{{data.contractsPartOriginal}}</span>
      </div>
      <div class="form-group_item">
        <label>本次合同主体:</label>
        <span>{{data.contractsPartA}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>合同期限:</label>
        <span>{{data.contractsTerm}}</span>
      </div>
      <div class="form-group_item">
        <label>试用期:</label>
        <span>{{data.isTrialPeriod?'是':'否'}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>合同开始日期:</label>
        <span>{{data.contractsBeginTime}}</span>
      </div>
      <div class="form-group_item">
        <label>合同截止日期:</label>
        <span>{{data.contractEndTime}}</span>
      </div>
    </div>
    <div class="form-group"  v-if="data.isTrialPeriod">
      <div class="form-group_item">
        <label>试用开始日期:</label>
        <span>{{data.beginTrailTime}}</span>
      </div>
      <div class="form-group_item">
        <label>试用截止日期:</label>
        <span>{{data.endTrailTime}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>审批类型:</label>
        <span>{{data.activityKeyName||''}}</span>
      </div>
    </div>
    <div class="detail-status status" :class=[$reconava.getStatusClass(data.status)]>
           <span class="status-name">
          {{$reconava.getStatusName(data.status)}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "form-info",
  data(){
    return {

    }
  },
  props:['data']
}
</script>

<style scoped lang="scss">
@import "../../common-style/form-info";
</style>